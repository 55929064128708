import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo-black.svg";

const NotFound = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 text-center px-3" style={{ backgroundColor: "#FFFFFF", color: "#1D2027" }}>
      {/* Fintech Logo */}
      <Link to="/">
        <img src={logo} alt="Fintech Logo" className="fintech-logo" style={{ marginBottom: "1rem" }} />
      </Link>

      <h1 className="display-1 fw-bold">404</h1>
      <h2 className="fw-semibold mb-3">Oops! Page Not Found</h2>
      <p className="text-muted mb-4" style={{ maxWidth: "500px" }}>
        The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
      </p>
      <a
        href="/"
        className="btn btn-lg px-4 pt-1"
        style={{
          backgroundColor: "#1D2027",
          color: "#FFFFFF",
          borderRadius: "20px",
        }}
      >
        Go to fintechglobal.center
      </a>
    </div>
  );
};

export default NotFound;
