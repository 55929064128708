import './App.css';
import React,{useEffect} from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Landing from './pages/Landing';
import OurProjects from './pages/OurProjects';
import { ProjectDetail } from './components/ProjectDetail';
import NotFound from './pages/NotFound';

function App() {
  useEffect(() => {
    // Initialize AOS when the component mounts
    AOS.init({
      duration: 500,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });


  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/projects/:pLink' element={<ProjectDetail/>} />
          <Route path='/projects/' element={<OurProjects />} />
          <Route path='*' element={<NotFound />} /> {/* Catch-all 404 route */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
